<template>
  <div class="bg-gray-100">
    <div class="container mx-auto py-8 h-full">
      <div class="mx-auto h-full max-w-7xl sm:px-6 mt-16 lg:px-8">
    <div
        class="relative isolate overflow-hidden px-6 py-20 text-center sm:px-16">
        <img src="@/assets/logo.png" class="w-96" style="display: block; margin: 0 auto;" />
        <p class="mx-auto max-w-2xl text-2xl tracking-tight mt-12 text-orange-600 sm:text-3xl">
           Procurando por um membro do Balcão de Milhas?
        </p>


        <div>
            <label
                class="mx-auto mt-8 relative bg-white min-w-sm max-w-2xl flex flex-col md:flex-row items-center justify-center border py-2 px-2 rounded-2xl gap-2 shadow-2xl focus-within:border-gray-300"
                for="search-bar">

                <input v-model="search" v-on:keyup.enter="onEnter" placeholder="Digite o @ do usuário aqui!" name="q"
                    class="px-6 py-2 w-full rounded-md flex-1 outline-none bg-white" required="">
                <button @click="onEnter"
                    class="w-full md:w-auto px-6 py-3 bg-orange-500 hover:bg-orange-600 text-white fill-white active:scale-95 duration-100 border will-change-transform overflow-hidden relative rounded-xl transition-all">
                    <div class="flex items-center transition-all opacity-1">
                        <span class="text-sm font-semibold whitespace-nowrap truncate mx-auto">
                            Pesquisar
                        </span>
                    </div>
                </button>
            </label>
          </div>

        <svg viewBox="0 0 1024 1024"
            class="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
            aria-hidden="true">
            <circle cx="512" cy="512" r="512" fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)" fill-opacity="0.7">
            </circle>
            <defs>
                <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                    <stop stop-color="#f6a53b"></stop>
                    <stop offset="1" stop-color="#f68c3b"></stop>
                </radialGradient>
            </defs>
        </svg>
    </div>
</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchView',
  data() {
    return {
      search: null
    }
  },
  async mounted() {

  },
  methods: {
    onEnter() {
      if(this.search){
      this.$router.push({ name: 'user', params: {username: this.search.trim()} })
    }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* From Uiverse.io by Nawsome */
</style>
