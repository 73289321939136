import { createRouter, createWebHistory } from 'vue-router'
import UserView from '../views/UserView.vue'
import SearchView from '../views/SearchView.vue'
import NotFoundView from '../views/NotFoundView.vue'

const routes = [
  {
    path: '/:username',
    name: 'user',
    component: UserView
  },
  {
    path: '/',
    name: 'search',
    component: SearchView
  },
  { path: '/:pathMatch(.*)*', 
  component: NotFoundView },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
