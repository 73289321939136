<template>
  <div class="bg-gray-100 h-screen">
    <div class="container mx-auto py-8 h-full">
      <section>
    <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div class="mx-auto max-w-screen-sm text-center">
            <h1 class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-orange-600">404</h1>
            <p class="mb-4 text-3xl tracking-tight font-bold text-orange-600 md:text-4xl">Essa página não existe</p>
            <p class="mb-4 text-lg font-light text-gray-500">Desculpe, não conseguimos encontrar essa página. </p>
            <button @click="$router.push('/')" class="inline-flex bg-orange-500 text-white hover:bg-orange-700 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4">Ir para a pesquisa</button>
        </div>   
    </div>
</section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFoundView',
  data() {
    return {

    }
  },
  async mounted() {

  },
  methods: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
