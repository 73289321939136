<template>
  <div class="bg-gray-100 h-screen">
    <div class="container mx-auto py-8 h-full">
      <div v-if="!loading && !error" id="profile" class="grid grid-cols-4 sm:grid-cols-12 gap-6 px-4">
        <div class="col-span-4 sm:col-span-3">
          <div class="bg-white shadow rounded-lg p-6">
            <div class="flex flex-col items-center">
              <img :src="data?.photo ?? require('@/assets/default.jpg')" class="w-32 h-32 bg-gray-300 rounded-full mb-4 shrink-0" />
              <div class="flex items-center space-x-2" v-if="!data.banned">
                <svg xmlns="http://www.w3.org/2000/svg" class="text-orange-500" width="20" height="20" viewBox="0 0 24 24"
                  stroke-width="2" fill="currentColor" stroke="currentColor" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path
                    d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z">
                  </path>
                </svg>
                <span class="text-lg font-semibold">{{ calcularMedia(data.reviews) }} <span class="text-gray-500">({{
                  data.reviews.length }})</span></span>
              </div>
              <p v-if="data.banned" class="text-gray-400 text-sm text-red-500 mb-3">Usuário banido</p>
              <p v-else class="text-gray-400 text-sm text-orange-500 mb-3">Usuário verificado</p>
              <h1 class="text-xl text-800">{{ data.username }}</h1>
              <p class="text-gray-400 text-sm">Criado em {{ data.date_created }}</p>
              <div v-if="!data.banned" class="mt-6 flex flex-wrap gap-4 justify-center">
                <a :href="`https://telegram.me/${data.username}`" target="_blank"
                  class="bg-orange-500 hover:bg-orange-600 font-bold text-white py-2 px-4 rounded">Contatar</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-4 sm:col-span-9">
          <div class="bg-white shadow rounded-lg p-6">
            <div v-if="data.banned" class="flex justify-center items-center font-bold py-32 px-4 text-lg text-gray-600">
              Este membro foi banido do Balcão de Milhas.
            </div>
            <div class="flex justify-center items-center font-bold py-32 px-4 text-lg text-gray-600" v-else-if="data?.reviews.length == 0">
              Este membro ainda não possui avaliações.
            </div>
            <div v-for="review in data?.reviews" v-bind:key="review"
              class="flex flex-col justify-between rounded-md p-8 mx-auto">

              <!-- stars -->
              <div class="text-orange-500 flex gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="text-orange-500" width="20" height="20" viewBox="0 0 24 24"
                  stroke-width="2" :fill="review.review_number >= 1 ? 'currentColor' : 'none'" stroke="currentColor"
                  stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path
                    d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z">
                  </path>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" class="text-orange-500" width="20" height="20" viewBox="0 0 24 24"
                  stroke-width="2" :fill="review.review_number >= 2 ? 'currentColor' : 'none'" stroke="currentColor"
                  stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path
                    d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z">
                  </path>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" class="text-orange-500" width="20" height="20" viewBox="0 0 24 24"
                  stroke-width="2" :fill="review.review_number >= 3 ? 'currentColor' : 'none'" stroke="currentColor"
                  stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path
                    d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z">
                  </path>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" class="text-orange-500" width="20" height="20" viewBox="0 0 24 24"
                  stroke-width="2" :fill="review.review_number >= 4 ? 'currentColor' : 'none'" stroke="currentColor"
                  stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path
                    d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z">
                  </path>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" class="text-orange-500" width="20" height="20" viewBox="0 0 24 24"
                  stroke-width="2" :fill="review.review_number >= 5 ? 'currentColor' : 'none'" stroke="currentColor"
                  stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path
                    d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z">
                  </path>
                </svg>
              </div>

              <p :class="['my-4 mb-0 text-base font-normal leading-relaxed tracking-wide', review?.review ? 'text-gray-900' : 'text-gray-400']">
                {{ review?.review ?? 'Sem nenhum comentário.' }}
              </p>


              <div class="mt-6 flex items-center gap-6 ">
                <div class="h-10 w-10 overflow-hidden rounded-full shadow-sm outline-neutral-800">
                  <div class="relative inline-block overflow-hidden rounded-lg border-neutral-800">
                    <img alt="" :src="review?.photo ?? require('@/assets/default.jpg')" width="50" height="50" decoding="async" data-nimg="1"
                      class="inline-block " loading="lazy" style="color: transparent;">
                  </div>
                </div>
                <div>
                  <p class="text-xs leading-relaxed tracking-wide text-gray-400">{{ new
                    Date(review.date).toLocaleString('pt-BR') }}</p>
                  <p class="leading-relaxed tracking-wide text-gray-800">{{ review.username }}</p>
                </div>
              </div>


            </div>
          </div>
        </div>
       
      </div>
      <div v-else-if="!error" class="flex justify-center items-center h-full">
        <svg class="pl" viewBox="0 0 160 160" width="160px" height="160px" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient id="grad" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stop-color="#000"></stop>
              <stop offset="100%" stop-color="#fff"></stop>
            </linearGradient>
            <mask id="mask1">
              <rect x="0" y="0" width="160" height="160" fill="url(#grad)"></rect>
            </mask>
            <mask id="mask2">
              <rect x="28" y="28" width="104" height="104" fill="url(#grad)"></rect>
            </mask>
          </defs>

          <g>
            <g class="pl__ring-rotate">
              <circle class="pl__ring-stroke" cx="80" cy="80" r="72" fill="none" stroke="hsl(30,100%,65%)"
                stroke-width="16" stroke-dasharray="452.39 452.39" stroke-dashoffset="452" stroke-linecap="round"
                transform="rotate(-45,80,80)"></circle>
            </g>
          </g>
          <g mask="url(#mask1)">
            <g class="pl__ring-rotate">
              <circle class="pl__ring-stroke" cx="80" cy="80" r="72" fill="none" stroke="hsl(30,100%,55%)"
                stroke-width="16" stroke-dasharray="452.39 452.39" stroke-dashoffset="452" stroke-linecap="round"
                transform="rotate(-45,80,80)"></circle>
            </g>
          </g>

          <g>
            <g stroke-width="4" stroke-dasharray="12 12" stroke-dashoffset="12" stroke-linecap="round"
              transform="translate(80,80)">
              <polyline class="pl__tick" stroke="hsl(30,100%,60%)" points="0,2 0,14"
                transform="rotate(-135,0,0) translate(0,40)"></polyline>
              <polyline class="pl__tick" stroke="hsl(30,100%,60%)" points="0,2 0,14"
                transform="rotate(-90,0,0) translate(0,40)"></polyline>
              <polyline class="pl__tick" stroke="hsl(30,100%,60%)" points="0,2 0,14"
                transform="rotate(-45,0,0) translate(0,40)"></polyline>
              <polyline class="pl__tick" stroke="hsl(30,100%,60%)" points="0,2 0,14"
                transform="rotate(0,0,0) translate(0,40)"></polyline>
              <polyline class="pl__tick" stroke="hsl(30,100%,60%)" points="0,2 0,14"
                transform="rotate(45,0,0) translate(0,40)"></polyline>
              <polyline class="pl__tick" stroke="hsl(30,100%,60%)" points="0,2 0,14"
                transform="rotate(90,0,0) translate(0,40)"></polyline>
              <polyline class="pl__tick" stroke="hsl(30,100%,60%)" points="0,2 0,14"
                transform="rotate(135,0,0) translate(0,40)"></polyline>
              <polyline class="pl__tick" stroke="hsl(30,100%,60%)" points="0,2 0,14"
                transform="rotate(180,0,0) translate(0,40)"></polyline>
            </g>
          </g>
          <g mask="url(#mask1)">
            <g stroke-width="4" stroke-dasharray="12 12" stroke-dashoffset="12" stroke-linecap="round"
              transform="translate(80,80)">
              <polyline class="pl__tick" stroke="hsl(30,900%,60%)" points="0,2 0,14"
                transform="rotate(-135,0,0) translate(0,40)"></polyline>
              <polyline class="pl__tick" stroke="hsl(30,900%,60%)" points="0,2 0,14"
                transform="rotate(-90,0,0) translate(0,40)"></polyline>
              <polyline class="pl__tick" stroke="hsl(30,900%,60%)" points="0,2 0,14"
                transform="rotate(-45,0,0) translate(0,40)"></polyline>
              <polyline class="pl__tick" stroke="hsl(30,900%,60%)" points="0,2 0,14"
                transform="rotate(0,0,0) translate(0,40)"></polyline>
              <polyline class="pl__tick" stroke="hsl(30,900%,60%)" points="0,2 0,14"
                transform="rotate(45,0,0) translate(0,40)"></polyline>
              <polyline class="pl__tick" stroke="hsl(30,900%,60%)" points="0,2 0,14"
                transform="rotate(90,0,0) translate(0,40)"></polyline>
              <polyline class="pl__tick" stroke="hsl(30,900%,60%)" points="0,2 0,14"
                transform="rotate(135,0,0) translate(0,40)"></polyline>
              <polyline class="pl__tick" stroke="hsl(30,900%,60%)" points="0,2 0,14"
                transform="rotate(180,0,0) translate(0,40)"></polyline>
            </g>
          </g>

          <g>
            <g transform="translate(64,28)">
              <g class="pl__arrows" transform="rotate(45,16,52)">
                <path fill="hsl(3,90%,55%)"
                  d="M17.998,1.506l13.892,43.594c.455,1.426-.56,2.899-1.998,2.899H2.108c-1.437,0-2.452-1.473-1.998-2.899L14.002,1.506c.64-2.008,3.356-2.008,3.996,0Z">
                </path>
                <path fill="hsl(30,100%,60%)"
                  d="M14.009,102.499L.109,58.889c-.453-1.421,.559-2.889,1.991-2.889H29.899c1.433,0,2.444,1.468,1.991,2.889l-13.899,43.61c-.638,2.001-3.345,2.001-3.983,0Z">
                </path>
              </g>
            </g>
          </g>
          <g mask="url(#mask2)">
            <g transform="translate(64,28)">
              <g class="pl__arrows" transform="rotate(45,16,52)">
                <path fill="hsl(333,90%,55%)"
                  d="M17.998,1.506l13.892,43.594c.455,1.426-.56,2.899-1.998,2.899H2.108c-1.437,0-2.452-1.473-1.998-2.899L14.002,1.506c.64-2.008,3.356-2.008,3.996,0Z">
                </path>
                <path fill="hsl(190,90%,80%)"
                  d="M14.009,102.499L.109,58.889c-.453-1.421,.559-2.889,1.991-2.889H29.899c1.433,0,2.444,1.468,1.991,2.889l-13.899,43.61c-.638,2.001-3.345,2.001-3.983,0Z">
                </path>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div v-else>
        <section>
    <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div class="mx-auto max-w-screen-sm text-center">
            <h1 class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-orange-600">404</h1>
            <p class="mb-4 text-3xl tracking-tight font-bold text-orange-600 md:text-4xl">Usuário não encontrado.</p>
            <p class="mb-4 text-lg font-light text-gray-500">Desculpe, não conseguimos encontrar essa página. </p>
            <button @click="$router.push('/')" class="inline-flex bg-orange-500 text-white hover:bg-orange-700 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4">Ir para a pesquisa</button>
        </div>   
    </div>
</section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'UserView',
  data() {
    return {
      data: null,
      loading: true,
      error: false
    }
  },
  async mounted() {
    try {
      const { data } = await axios.get('https://api.balcaodemilhas.com/api/avaliations',{ params: { username: this.$route.params.username.replace(/@/,'') } })

      console.log(data)

      data.data.reviews = data.data.reviews?.reverse()

      this.data = data.data

      this.loading = false
    } catch(err) {
      this.error = true
    }
  },
  methods: {
    calcularMedia(notas) {
      // Verifica se o array está vazio
      if (notas.length === 0) return 0;

      // Extrai as notas e calcula a soma
      const soma = notas.reduce((acc, obj) => acc + Number(obj.review_number), 0);

      // Calcula a média
      const media = soma / notas.length;

      return media.toFixed(1); // Retorna a média com uma casa decimal
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* From Uiverse.io by Nawsome */
.pl {
  display: block;
  width: 9.375em;
  height: 9.375em;
}

.pl__arrows,
.pl__ring-rotate,
.pl__ring-stroke,
.pl__tick {
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.pl__arrows {
  animation-name: arrows42;
  transform: rotate(45deg);
  transform-origin: 16px 52px;
}

.pl__ring-rotate,
.pl__ring-stroke {
  transform-origin: 80px 80px;
}

.pl__ring-rotate {
  animation-name: ringRotate42;
}

.pl__ring-stroke {
  animation-name: ringStroke42;
  transform: rotate(-45deg);
}

.pl__tick {
  animation-name: tick42;
}

.pl__tick:nth-child(2) {
  animation-delay: -1.75s;
}

.pl__tick:nth-child(3) {
  animation-delay: -1.5s;
}

.pl__tick:nth-child(4) {
  animation-delay: -1.25s;
}

.pl__tick:nth-child(5) {
  animation-delay: -1s;
}

.pl__tick:nth-child(6) {
  animation-delay: -0.75s;
}

.pl__tick:nth-child(7) {
  animation-delay: -0.5s;
}

.pl__tick:nth-child(8) {
  animation-delay: -0.25s;
}

/* Animations */
@keyframes arrows42 {
  from {
    transform: rotate(45deg);
  }

  to {
    transform: rotate(405deg);
  }
}

@keyframes ringRotate42 {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(720deg);
  }
}

@keyframes ringStroke42 {

  from,
  to {
    stroke-dashoffset: 452;
    transform: rotate(-45deg);
  }

  50% {
    stroke-dashoffset: 169.5;
    transform: rotate(-180deg);
  }
}

@keyframes tick42 {

  from,
  3%,
  47%,
  to {
    stroke-dashoffset: -12;
  }

  14%,
  36% {
    stroke-dashoffset: 0;
  }
}

</style>
